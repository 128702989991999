export const footerLinks1 = [
  { href: "/about", text: "Company Information" },
  { href: "/privacy", text: "Privacy Policy" },
  { href: "/terms", text: "Terms & Conditions" },
  { href: "/blog", text: "Blog" },
  { href: "/contact", text: "Contact Us" },
  { href: "/export", text:"Worldwide Distributions"},
  { href: "/shipping-and-delivery", text:"Shipping & Delivery"},
  { href: "/refund-policy", text:"Refund Policy"}
];
export const footerLinks2 = [
  { href: "/shop-2", text: "Eau de Parfum" },
  { href: "/shop-3", text: "Concentrated Parfum" },
  { href: "/shop-4", text: "Dhakoon" },
  { href: "/product-category/gift-sets", text: "Gift Sets" },
  { href: "/shop", text: "Collections" },
];
export const footerLinks3 = [
  { href: "/about", text: "Customer Service" },
  { href: "/account_dashboard", text: "My Account" },
  { href: "/store_location", text: "Find a Store" },
  { href: "/terms", text: "Legal & Privacy" },
  { href: "/contact", text: "Contact" },
  { href: "/about", text: "Gift Card" },
];

export const languageOptions = [
  { value: "en", text: "English" },
  { value: "ar", text: "عربي" },
];

export const languageOptions2 = [
  { value: "en", text: "English" },
  { value: "ar", text: "عربي" },
  // { value: "french", text: "French" },
  // { value: "swedish", text: "Swedish" },
];

export const currencyOptions = [
  { value: "/", text: "UAE", selected: true, link: "https://ae.ahmedalmaghribi.com" },
  { value: "om", text: "OMAN", link: "https://om.ahmedalmaghribi.com" },
  { value: "ksa", text: "KSA", link: "https://ksa.ahmedalmaghribi.com" },
  { value: "bh", text: "BAHRAIN", link: "https://bh.ahmedalmaghribi.com" },
  { value: "kw", text: "KUWAIT", link: "https://kw.ahmedalmaghribi.com" },
  { value: "qa", text: "QATAR", link: "https://qa.ahmedalmaghribi.com" },
];


export const socialLinks = [
  {
    href: "https://www.facebook.com/ahmedalmaghribiperfumesllc/",
    className: "svg-icon svg-icon_facebook",
    width: 9,
    height: 15,
    viewBox: "0 0 9 15",
    icon: "#icon_facebook",
  },
  {
    href: "https://www.instagram.com/ahmedalmaghribiperfumes/",
    className: "svg-icon svg-icon_instagram",
    width: 14,
    height: 13,
    viewBox: "0 0 14 13",
    icon: "#icon_instagram",
  },
  
  {
    href: "https://www.youtube.com/channel/UCzUsyi2q3zCR4YTeAA1okkg",
    className: "svg-icon svg-icon_youtube",
    width: 16,
    height: 11,
    viewBox: "0 0 16 11",
    icon: (
      <path d="M15.0117 1.8584C14.8477 1.20215 14.3281 0.682617 13.6992 0.518555C12.5234 0.19043 7.875 0.19043 7.875 0.19043C7.875 0.19043 3.19922 0.19043 2.02344 0.518555C1.39453 0.682617 0.875 1.20215 0.710938 1.8584C0.382812 3.00684 0.382812 5.46777 0.382812 5.46777C0.382812 5.46777 0.382812 7.90137 0.710938 9.07715C0.875 9.7334 1.39453 10.2256 2.02344 10.3896C3.19922 10.6904 7.875 10.6904 7.875 10.6904C7.875 10.6904 12.5234 10.6904 13.6992 10.3896C14.3281 10.2256 14.8477 9.7334 15.0117 9.07715C15.3398 7.90137 15.3398 5.46777 15.3398 5.46777C15.3398 5.46777 15.3398 3.00684 15.0117 1.8584ZM6.34375 7.68262V3.25293L10.2266 5.46777L6.34375 7.68262Z" />
    ),
  },
  
  {
    href: "https://www.tiktok.com/@ahmedalmaghribiperfumes",
    className: "svg-icon svg-icon_tiktok",
    width: 14,
    height: 16,
    viewBox: "0 0 12 14",
    icon: (
      <path d="M11.5 3.1c-.73 0-1.43-.2-2.04-.56A5.4 5.4 0 0 1 7.8.03c-.03-.02-.06-.03-.1-.03H5.3c-.1 0-.18.08-.18.18v8.03a1.47 1.47 0 1 1-1.47-1.47c.32 0 .6.1.85.26.08.05.18 0 .18-.1V5.7a.18.18 0 0 0-.2-.18 3.7 3.7 0 1 0 2.1 3.36V4.43a6 6 0 0 0 2.94.74h.02c.1 0 .18-.08.18-.18V3.3c0-.1-.08-.18-.18-.18Z" />
    ),
  },
  
];
